import Link from 'next/link';
import { useObjectsDispatch } from '../../context/objects';
import css from '../../styles/modules/home/HomeWidgetCommercial.module.scss';

const HomeWidgetCommercial = ({ text , region}) => {
  /** STATES */
  const setObjectsState = useObjectsDispatch();

  /** FUNCTIONS */
  const handleOnClick = () => {
    setObjectsState({
      type: 'SET_FILTER',
      payload: {
        type: 'продажа',
        category: 'квартира',
        noCommission: 1
      }
    });
  };

  /** TEMPLATE */
  return (
    <Link href={`/${region.region}/objects/buy/flats?noCommission=1`} className={css.commercial}>
      <div className={css.commercial} onClick={handleOnClick}>
        {text}
        <svg className={css.commercialIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="7" y1="17" x2="17" y2="7"/><polyline points="7 7 17 7 17 17"/></svg>
      </div>
    </Link>
  );
};

HomeWidgetCommercial.defaultProps = {
  title: '',
  text: ''
};

export default HomeWidgetCommercial;
