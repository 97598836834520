import Skelet from '../Skelet';
import css from '../../styles/modules/Carousel.module.scss';

const HomeCarouselSkeletonItem = () => {
  return (
    <div className={css.carouselItem}>
    </div>
  );
};

export default HomeCarouselSkeletonItem;
