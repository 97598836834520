import { useState } from 'react';
import Link from 'next/link';
import { useObjectsProvider } from '../../context/objects';
import { useLocationProvider } from '../../context/location';
import { useBuildingsDispatch } from '../../context/buildings';
import { useCookies } from "react-cookie";
import Button from '../../components/Button';
import Image from 'next/image';
import css from '../../styles/modules/home/HomeUsefool.module.scss';

import { useRouter } from 'next/router';

import UsefoolMap from '../../public/images/usefoolMap.svg';

const HomeUsefool = (location) => {
  let region = location.region;
  let locations = location.locations;
  const setBuildingsState = useBuildingsDispatch();
  const [locationState, setLocationState] = useLocationProvider();
  const [objectsState, setObjectsState] = useObjectsProvider();
  const [cookie, setCookie] = useCookies(["region"]);

  const [isExpanded, setIsExpanded] = useState(false);

  const router = useRouter();

  /** FUNCTIONS */
  const handleOnClick = (e) => {
    setIsExpanded(!isExpanded);
  };

  /** HANDLERS */
  const handleLocationClick = (v) => {
    if (locationState.code === v.code)
      return;

    setLocationState({ type: 'SET_LOCATION', payload: { ...locationState, ...v } });

    const newReference = [];
    newReference.locality = [];

    let currentDate = new Date();
    let cookieDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());

    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.NEXT_PUBLIC_API + '/objects/reference', { region: v.region });

        if (!response.data || response.data.status !== 'ok')
          throw new Error(response.data.text);

        if (response.data.data.location['localityName'].length > 0) {
          response.data.data.location['localityName'].map((l) => (
            newReference.locality.push({ type: 'option', text: l, value: l })
          ));

          setObjectsState({ type: 'SET_REFERENCE', payload: newReference });
        }
      } catch (error) {
      }
    };
    fetchData();
    localStorage.setItem('location', v.code);
    setCookie("region", v.code, {
      path: "/",
      expires: cookieDate
    });

    router.replace('/' + v.code);
  };

  return (
    <section className="block">
      <div className="fixed">
        <div className={css.tools}>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Купить квартиру</div>
            <Link href={['/', region, '/objects/buy/flats/1k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'квартира',
                      rooms: ['1']
                    }
                  });
                }}
              >
                1-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/flats/2k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'квартира',
                      rooms: ['2']
                    }
                  });
                }}
              >
                2-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/flats/3k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'квартира',
                      rooms: ['3']
                    }
                  });
                }}
              >
                3-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/flats/studio'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'квартира',
                      studio: 1
                    }
                  });
                }}
              >
                Студии
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/flats/rooms'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'квартира',
                      studio: 1
                    }
                  });
                }}
              >
                Комнаты
              </a>
            </Link>
          </div>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Снять квартиру</div>
            <Link href={['/', region, '/objects/rent/flats/1k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'квартира',
                      rooms: ['1']
                    }
                  });
                }}
              >
                1-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/flats/2k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'квартира',
                      rooms: ['2']
                    }
                  });
                }}
              >
                2-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/flats/3k'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'квартира',
                      rooms: ['3']
                    }
                  });
                }}
              >
                3-комнатные
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/flats/studio'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'квартира',
                      studio: 1
                    }
                  });
                }}
              >
                Студии
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/flats/rooms'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'квартира',
                      studio: 1
                    }
                  });
                }}
              >
                Комнаты
              </a>
            </Link>
          </div>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Загородная недвижимость</div>
            <Link href={['/', region, '/objects/buy/houses'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'дом'
                    }
                  });
                }}
              >
                Купить дом
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/sites'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'участок'
                    }
                  });
                }}
              >
                Купить земельный участок
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/houses'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'дом'
                    }
                  });
                }}
              >
                Снять дом надолго
              </a>
            </Link>
          </div>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Новостройки</div>
            <Link href={['/', region, '/buildings'].join('')} passHref>
              <a role="button" tabIndex={0}>
                Каталог жк
              </a>
            </Link>
            <Link href={['/', region, '/buildings'].join('')} passHref>
              <a role="button"
                tabIndex={0}
                onClick={() => {
                  setBuildingsState({
                    type: 'SET_FILTER',
                    payload: {
                      deadlineYear: "2023",
                      deadlineQuartal: "4"
                    }
                  });
                }}>
                Сдача до конца 2023
              </a>
            </Link>
            <Link href={['/', region, '/buildings'].join('')} passHref>
              <a role="button"
                tabIndex={0}
                onClick={() => {
                  setBuildingsState({
                    type: 'SET_FILTER',
                    payload: {
                      deadlineYear: "2024",
                      deadlineQuartal: "4"
                    }
                  });
                }}>
                Сдача до конца 2024
              </a>
            </Link>
          </div>

          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Коммерчесская недвижимость</div>
            <Link href={['/', region, '/objects/buy/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'коммерция',
                      commercialType: ['офисное помещение']
                    }
                  });
                }}
              >
                Купить офис
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'коммерция',
                      commercialType: ['офисное помещение']
                    }
                  });
                }}
              >
                Купить склад
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'коммерция',
                      commercialType: ['производственное помещение']
                    }
                  });
                }}
              >
                Купить производство
              </a>
            </Link>
            <Link href={['/', region, '/objects/buy/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'продажа',
                      category: 'коммерция',
                      commercialType: ['готовый бизнес']
                    }
                  });
                }}
              >
                Купить готовый бизнес
              </a>
            </Link>
          </div>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Аренда <br />коммерции</div>
            <Link href={['/', region, '/objects/rent/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'коммерция',
                      commercialType: ['офисное помещение']
                    }
                  });
                }}
              >
                Арендовать офис
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'коммерция',
                      commercialType: ['офисное помещение']
                    }
                  });
                }}
              >
                Арендовать склад
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'коммерция',
                      commercialType: ['производственное помещение']
                    }
                  });
                }}
              >
                Арендовать производство
              </a>
            </Link>
            <Link href={['/', region, '/objects/rent/commercial'].join('')} passHref>
              <a
                role="button"
                tabIndex={0}
                onClick={() => {
                  setObjectsState({
                    type: 'SET_FILTER',
                    payload: {
                      type: 'аренда',
                      category: 'коммерция',
                      commercialType: ['торговое помещение']
                    }
                  });
                }}
              >
                Снять торговое помещение
              </a>
            </Link>
          </div>
          <div className={css.toolsItem}>
            <div className={css.searchByMap}>
              <div className={css.searchByMapText}>
                <div className={css.toolsItemTitle}>Поиск на карте</div>
                <div>
                  Ищите жилье рядом с работой,
                  <br />
                  друзьями или озером
                </div>
                <Link href={['/', region, '/objects/map'].join('')} passHref>
                  <a className={css.toolsItemButton} type="button">
                    Искать на карте
                  </a>
                </Link>
              </div>
              <div className={css.usefoolMap}>
                <UsefoolMap />
              </div>
            </div>

          </div>
        </div>

        <div className={css.tools_regions}>
          <div className={css.toolsItem}>
            <div className={css.toolsItemTitle}>Недвижимость по регионам</div>
            <div className={[css.toolsItemRegions, isExpanded && css.active].join(' ')}>
              {locations.map((value, index) => (
                <Link key={index} href={['/', value.code].join('')} passHref>
                  <a
                    key={index}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleLocationClick(value)}
                  >
                    {value.region}
                  </a>
                </Link>
              ))}
            </div>
            <Button
              className={css.searchButton}
              onClick={handleOnClick}
              style={{ marginLeft: 'auto' }}
            >
              {isExpanded ? 'Свернуть регионы' : `Показать все регионы`}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeUsefool;
