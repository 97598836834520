import { useState, useEffect, Fragment } from 'react';
import css from '../../styles/modules/home/HomeSearchCheckbox.module.scss';

const HomeSearchCheckbox = ({ checkboxes, onChange }) => {
  /** STATES */
  const [value, setValue] = useState([]);

  /** HANDLERS */
  const handleCheckboxClick = (v) => {
    setValue(value.includes(v) ? value.filter((item) => item !== v) : value.concat([v]));
  };

  /** HOOKS */
  useEffect(() => {
    if (onChange)
      onChange(value);
  }, [value]);

  /** TEMPLATE */
  return (
    <div className={css.checkbox}>
      {checkboxes.map((v, i) => (
        <Fragment key={i}>
          <button
            type="button"
            className={[css.checkboxButton, value.includes(v.value) && css.active]
              .filter(Boolean)
              .join(' ')}
            onClick={() => handleCheckboxClick(v.value)}
          >
            {v.text}
          </button>
          {i < Object.keys(checkboxes).length - 1 && <div className={css.checkboxSplitter} />}
        </Fragment>
      ))}
    </div>
  );
};

export default HomeSearchCheckbox;
