import { useState, useEffect, useRef } from 'react';
import css from '../../styles/modules/home/HomeSearchSelect.module.scss';

const HomeSearchSelect = ({ options, selected, onChange }) => {
  /** STATES */
  const [value, setValue] = useState(selected);
  const [isOpen, setIsOpen] = useState(false);

  /** REFS */
  const parentEl = useRef();

  /** FUNCTIONS */
  const handleOutsideClick = (e) => {
    if (e.target.closest(`.${css.select}`) !== parentEl.current)
      setIsOpen(false);
  };
  const canDropdownDown = () => {
    const dropdownHeight = options.length * 50;
    const parentElPosition = parentEl.current.getBoundingClientRect();

    if (
      parentElPosition.top + parentElPosition.height + Math.min(dropdownHeight, 320) >
      window.innerHeight
    )
      return false;

    return true;
  };

  /** HOOKS */
  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);
  }, []);
  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);
  useEffect(() => {
    setValue(selected);
  }, [selected]);

  /** TEMPLATE */
  return (
    <div className={css.select} ref={parentEl}>
      <button type="button" className={css.selectView} onClick={() => setIsOpen(!isOpen)}>
        <span>{value[0].toUpperCase() + value.substring(1)}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            position: 'absolute',
            right: 8,
            transition: 'all 200ms',
            transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'
          }}
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>
      {isOpen && (
        <div
          className={css.selectDropdown}
          style={!canDropdownDown() ? { top: 'auto', bottom: 'calc(100% + 6px)' } : null}
        >
          {options.map((v, i) => {
            if (v.hidden) return null;

            return (
              <button
                type="button"
                className={[css.selectDropdownItem, value === v.value && css.active]
                  .filter(Boolean)
                  .join(' ')}
                onClick={() => {
                  setValue(v.value);
                  setIsOpen(false);
                }}
                key={i}
              >
                {v.text}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HomeSearchSelect;
