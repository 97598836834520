import { useState, useEffect, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import css from '../../styles/modules/home/HomeSearchInput.module.scss';

const HomeSearchInput = ({ inputs, onChange }) => {
  /** STATES */
  const [value, setValue] = useState(['', '']);

  /** HOOKS */
  useEffect(() => {
    if (onChange)
      onChange(value);
  }, [value]);

  /** TEMPLATES */
  return (
    <div className={css.input}>
      {inputs.map((v, i) => (
        <Fragment key={i}>
          <NumberFormat
            thousandSeparator=" "
            placeholder={v.placeholder}
            allowNegative={false}
            suffix={v.suffix}
            isAllowed={({ formattedValue, floatValue }) =>
              formattedValue === '' || floatValue <= (v.max || 9999999999)
            }
            onValueChange={(x) => {
              const newValue = [...value];
              newValue[i] = Number(x.value) ? Number(x.value).toString() : '';

              setValue(newValue);
            }}
          />
          {i < Object.keys(inputs).length - 1 && <div className={css.inputSplitter} />}
        </Fragment>
      ))}
    </div>
  );
};

export default HomeSearchInput;
