import css from '../styles/modules/Skelet.module.scss';

const Skelet = ({ children, height, maxHeight, width, maxWidth, style, className, invert }) => {
  return (
    <div
      className={[css.skelet, className, invert && css.invert].filter(Boolean).join(' ')}
      style={{ height, maxHeight, width, maxWidth, ...style }}
    >
      {children}
    </div>
  );
};

export default Skelet;
