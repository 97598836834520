import { useEffect, useState, Suspense } from 'react';
import Image from 'next/image';
import Button from '../Button';
import Skelet from '../Skelet';
import Link from 'next/link';
import HomeNewsSkeletonItem from './HomeNewsSkeletonItem';
import { useLocationState } from '../../context/location';
import axios from 'axios';
import { JSONToQuery } from '../../helpers';

import css from '../../styles/modules/home/HomeNews.module.scss';

const HomeNews = () => {
  /** STATES */
  const locationState = useLocationState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!locationState.isLoaded) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.NEXT_PUBLIC_API + `/faq/top` + `${JSONToQuery({
          location: locationState.region,
          length: 10
        })}`);


        if (!response.data || response.data.status !== 'ok') {
          console.log('err');
          throw new Error(response.data.text);
        }

        setData(response.data.data.data);
        setIsLoading(false);
        setIsError(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };

    fetchData();

  }, [locationState]);

  /** TEMPLATES */
  if (isLoading || isError)
    return (
      <section className="block crop">
        <div className="fixed">
          <h3>
            <Skelet style={{ maxWidth: 400, width: '100%', height: 47.5 }} />
          </h3>
          <div className={css.journal}>
            <div className={css.journalGroup}>
              <HomeNewsSkeletonItem key="1" />
            </div>
          </div>
        </div>
      </section>
    );

  const DateFormatter = new Intl.DateTimeFormat('ru-Ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  let lastNews = data.length > 0 ? data[0] : null;

  if (data.length)
    return (
      <section className="block crop">
        <div className="fixed">
          <h3>Новости</h3>
          <div className={css.journal}>

            <div className={css.journalGroup}>
              {data && data.slice(1, 5).map((value, index) => (
                <Suspense fallback={<HomeNewsSkeletonItem />} key={index}>
                  <a className={css.journalGroupItem} href={`/${value.slugCategory}/${value.slug}`}>
                    <Image className={css.journalItemImage} src={value.image} alt="" width={100} height={60} />
                    <div className={css.journalItemContent}>
                      <span>{value.title}</span>
                      <div className={css.journalItemFooter}>
                        <div className={css.journalItemCategories}>
                          {value.category.length ? value.category.join(' | ') : ''}
                        </div>
                        <div className={css.journalItemDate}>
                          {DateFormatter.format(new Date(value.created_at))}
                        </div>
                      </div>
                    </div>
                  </a>
                </Suspense>
              )
              )}
              <div className={css.journalGroupAction}>
                <Button type="link" href="/news">
                  Все новости
                </Button>
              </div>
            </div>
            {lastNews && (
              <a href={`/${lastNews.slugCategory}/${lastNews.slug}`} className={css.journalItem}>
                {lastNews.image && <Image
                  className={css.journalItemBackgroundImage}
                  src={lastNews.image}
                  layout="fill"
                  objectFit="cover"
                  alt=""
                  priority
                />}
                <div className={css.journalItemBackground} />
                <div className={css.journalItemContent}>
                  <div className={css.journalItemTitle}>{lastNews.title}</div>
                  <div className={css.journalItemDescription}>
                    {lastNews.short_body}
                  </div>
                  <div className={css.journalItemFooter}>
                    <span className={css.journalItemDate}>{DateFormatter.format(new Date(lastNews.created_at))}</span>
                    <div className={css.journalItemReadMore}>
                      <span>
                          Читать дальше
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ position: 'relative', top: -1 }}
                      >
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
      </section>
    );

  return
  <>
  </>
};

export default HomeNews;
