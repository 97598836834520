import { useState, useEffect } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import Image from 'next/image';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HomeSearch from './HomeSearch';
import HomeWidgetCommercial from './HomeWidgetCommercial';
import css from '../../styles/modules/home/HomeWidget.module.scss';

const titleTexts = ['Покупка', 'Аренда', /*'Оформление',*/ 'Продвижение', 'Продажа', 'Сдача'];

const HomeWidget = ( region ) => {
  /** STATES */
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(0);

  /** HOOKS */
  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 1 * 3000);
    return () => clearTimeout(intervalId);
  }, []);

  /** TEMPLATE */
  return (
    <section className={['block', css.block].join(' ')}>
      <div className="main">
        <div className={css.widget}>
          <div className={css.widgetImage}>
            <Image src="/images/main-widget5.webp" layout="fill" objectFit="cover" alt="" priority={width > 992} />
          </div>
          <div className={css.widgetSearch}>
            <div className={css.widgetSearchSlogan}>
              <TextTransition springConfig={presets.gentle} inline={true}>
                {titleTexts[index % titleTexts.length]}
              </TextTransition>
              <span>недвижимости</span>
            </div>
            <HomeSearch />
            {/* <HomeWidgetCommercial text="Квартиры без комиссии агентства" region={region}/> */}
          </div>
        </div>
      </div>
    </section>
  );
};


export default HomeWidget;
