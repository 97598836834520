import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import css from '../styles/modules/Checkbox.module.scss';

const Checkbox = forwardRef(
  ({ title, view, checkboxes, selected, single, visibility, style, onChange }, ref) => {
    /** STATES */
    const [cb, setCb] = useState(() => {
      const values = Array.isArray(selected) ? selected : [selected];
      const validatedValues = checkboxes
        .filter((a) => values.includes(a.value.toString()))
        .map((x) => x.value.toString());

      return single ? (validatedValues[0] ? [validatedValues[0]] : []) : validatedValues;
    });

    /** HANDLERS */
    const handleCheckboxClick = (v) => {
      const newCb = cb.includes(v)
        ? cb.filter((item) => item !== v)
        : single
        ? [v]
        : cb.concat([v]);

      setCb(newCb);
      if (onChange)
        onChange(newCb);
    };

    /** HOOKS */
    useImperativeHandle(ref, () => ({
      get() {
        return cb;
      },
      set(v) {
        const values = Array.isArray(v) ? v : [v];
        const validatedValues = checkboxes
          .filter((a) => values.includes(a.value.toString()))
          .map((x) => x.value.toString());

        setCb(single ? [validatedValues[0]] : validatedValues);
      }
    }));
    useEffect(() => {
      if (!selected.length) setCb([]);
      else setCb(Array.isArray(selected) ? (single ? [selected[0]] : selected) : [selected]);
    }, [selected]);

    /** TEMPLATE */
    if (!checkboxes.length || visibility === false)
      return null;

    return (
      <div
        className={[css.cb, view !== 'box' && css[view]].filter(Boolean).join(' ')}
        style={style}
      >
        {title && <div className={css.cbTitle}>{title}</div>}
        <div className={[css.cbContent, css.inline].join(' ')}>
          {checkboxes.map((value, index) => (
            <button
              type="button"
              className={[
                css.cbChekbox,
                cb.includes(value.value) && css.active,
                value.disabled && css.disabled
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={() => handleCheckboxClick(value.value)}
              disabled={value.disabled}
              key={index}
            >
              {value.text}
            </button>
          ))}
        </div>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
  checkboxes: [],
  selected: [],
  visibility: true,
  view: 'box'
};

export default Checkbox;
