import dynamic from 'next/dynamic';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLocationState } from '../../context/location';
import { useObjectsDispatch } from '../../context/objects';
import Input from '../Input';
import Radio from '../Radio';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Select from '../Select';
import css from '../../styles/modules/home/HomeWidgetMobile.module.scss';
import { declOfNum, JSONToQuery, sleep } from '../../helpers';

const Modal = dynamic(() => import('../Modal'));

const HomeWidgetMobile = () => {
  /** STATES */
  const router = useRouter();
  const locationState = useLocationState();
  const setObjectsState = useObjectsDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [foundCount, setFoundCount] = useState(0);
  const [isModalSuggestVisible, setIsModalSuggestVisible] = useState(false);
  const [isModalSuccessVisible, setIsModalSuccessVisible] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [objectType, setObjectType] = useState('продать');
  const [objectCategory, setObjectCategory] = useState('квартира');
  const [objectAddress, setObjectAddress] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();
  const [type, setType] = useState('продажа');
  const [category, setCategory] = useState('квартира');
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [studio, setStudio] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [squareFrom, setSquareFrom] = useState('');
  const [squareTo, setSquareTo] = useState('');
  const [squareAreaFrom, setSquareAreaFrom] = useState('');
  const [squareAreaTo, setSquareAreaTo] = useState('');
  const [commercialType, setCommercialType] = useState([]);

  /** FUNCTIONS */
  const handleSearchClick = () => {
    const newSearch = {};
    let newLink = '/' + locationState.code + '/objects';

    switch (type) {
      default:
      case 'продажа':
        newSearch.type = 'продажа';
        newLink += '/buy';
        break;

      case 'аренда':
        newSearch.type = 'аренда';
        newLink += '/rent';
        break;
    }
    switch (category) {
      default:
      case 'квартира':
        newSearch.category = 'квартира';
        newLink += '/flats';
        break;

      case 'комната':
        newSearch.category = 'комната';
        newLink += '/rooms';
        break;

      case 'дом':
        newSearch.category = 'дом';
        newLink += '/houses';
        break;

      case 'участок':
        newSearch.category = 'участок';
        newLink += '/sites';
        break;

      case 'коммерция':
        newSearch.category = 'коммерция';
        newLink += '/commercial';
        break;
    }

    newSearch.priceFrom = priceFrom;
    newSearch.priceTo = priceTo;

    if (category === 'квартира') {
      if (studio && rooms.length === 0) newLink += '/studio';
      else if (!studio && rooms.length === 1) {
        switch (rooms[0]) {
          case '1':
            newLink += '/1k';
            break;
  
          case '2':
            newLink += '/2k';
            break;
  
          case '3':
            newLink += '/3k';
            break;
  
          case '4':
            newLink += '/4k';
            break;
  
          default:
            break;
        }
      }
    }
    if (category === 'квартира' && (rooms.length || studio)) {
      if (studio) newSearch.studio = 1;
      newSearch.rooms = rooms.filter((it) => it !== 'студия');
    }

    if (category === 'дом' || category === 'комната') {
      newSearch.squareFrom = squareFrom;
      newSearch.squareTo = squareTo;
    }

    if (category === 'коммерция') {
      newSearch.commercialType = commercialType;
    }

    if (category === 'участок') {
      newSearch.squareAreaFrom = squareAreaFrom;
      newSearch.squareAreaTo = squareAreaTo;
    }

    setObjectsState({
      type: 'SET_FILTER',
      payload: newSearch
    });
    setObjectsState({
      type: 'SET_SORTING',
      payload: {}
    });
    setObjectsState({
      type: 'SET_PAGINATION',
      payload: {}
    });
    router.push(
      `${newLink}${JSONToQuery(newSearch, [
        'type',
        'category',
        category === 'квартира' && newSearch.studio && !newSearch.rooms.length && 'studio',
        category === 'квартира' && !newSearch.studio && newSearch.rooms && newSearch.rooms.length === 1 && 'rooms'
      ])}`
    );
  };
  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsModalSuggestVisible(false);
      setIsModalSuccessVisible(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone,
        pathName: '/',
        objectFormat: objectType,
        categoryType: objectCategory,
        objectAddress,
        whatComponent: 'HomeWidgetMobile',
        location: locationState.region,
        platform: 'web'
      });
    }
  };
  const handleCommercialClick = () => {
    setObjectsState({
      type: 'SET_FILTER',
      payload: {
        type: 'продажа',
        category: 'квартира',
        noCommission: 1
      }
    });

    router.push(`/${locationState.code}/objects/buy/flats?noCommission=1`);
  };

  /** HOOKS */
  useEffect(() => {
    if (!locationState.isLoaded)
      return;

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setIsLoading(true);
      await sleep(300);

      try {
        const response = await axios.post(process.env.NEXT_PUBLIC_API + '/objects', {
          type,
          category,
          rooms: category === 'квартира' ? rooms : [],
          studio: category === 'квартира' ? studio : 0,
          priceFrom,
          priceTo,
          squareFrom: category === 'дом' ? squareFrom : '',
          squareTo: category === 'дом' ? squareTo : '',
          squareAreaFrom: category === 'участок' ? squareAreaFrom : '',
          squareAreaTo: category === 'участок' ? squareAreaTo : '',
          commercialType: category === 'коммерция' ? commercialType : '',
          location: locationState.region,
          stat: 1
        }, { signal });

        if (!response.data || response.data.status !== 'ok')
          throw new Error(response.data.text);
  
        setFoundCount(response.data.data.count);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchData();

    return () => controller.abort();
  }, [locationState, type, category, studio, rooms, priceFrom, priceTo, squareFrom, squareTo, squareAreaFrom, squareAreaTo, commercialType]);

  /** TEMPLATE */
  return (
    <section className={['block', css.block].join(' ')}>
      <div className="fixed">
        <Radio
          title="Тип сделки"
          radios={[
            { text: 'Купить', value: 'продажа' },
            { text: 'Снять', value: 'аренда', disabled: category === 'участок' }
          ]}
          selected={type}
          onChange={(v) => setType(v)}
        />
        <Radio
          title="Категория объекта"
          radios={[
            { text: 'Квартира', value: 'квартира' },
            { text: 'Комната', value: 'комната' },
            { text: 'Дом', value: 'дом' },
            { text: 'Участок', value: 'участок', disabled: type === 'аренда' },
            { text: 'Коммерция', value: 'коммерция' }
          ]}
          selected={category}
          onChange={(v) => setCategory(v)}
        />
        <Input
          title="Стоимость"
          inputs={[
            {
              placeholder: 'Цена от',
              mask: 'money',
              value: priceFrom,
              max: 99999999
            },
            {
              placeholder: 'Цена до',
              mask: 'money',
              value: priceTo,
              max: 99999999
            }
          ]}
          onChange={(v) => {
            setPriceFrom(v[0]);
            setPriceTo(v[1]);
          }}
          suffix="₽"
        />
        <Checkbox
          title="Количество комнат"
          checkboxes={[
            { text: 'Студия', value: 'студия' },
            { text: '1К', value: '1' },
            { text: '2К', value: '2' },
            { text: '3К', value: '3' },
            { text: '4К+', value: '4' }
          ]}
          visibility={['квартира'].includes(category)}
          selected={studio ? ['студия', ...rooms] : rooms}
          onChange={(v) => {
            if (v.includes('студия')) setStudio(1);
            else setStudio(0);

            setRooms(v.filter((x) => x !== 'студия'));
          }}
        />
        <Input
          title="Общая площадь"
          inputs={[
            {
              placeholder: 'Площадь от',
              mask: 'number',
              value: squareFrom,
              max: 999999
            },
            {
              placeholder: 'Площадь до',
              mask: 'number',
              value: squareTo,
              max: 999999
            }
          ]}
          visibility={['квартира', 'комната', 'дом'].includes(category)}
          suffix="м²"
          onChange={(v) => {
            setSquareFrom(v[0]);
            setSquareTo(v[1]);
          }}
        />
        <Input
          title="Площадь участка"
          inputs={[
            {
              placeholder: 'Площадь от',
              mask: 'number',
              value: squareAreaFrom,
              max: 999999
            },
            {
              placeholder: 'Площадь до',
              mask: 'number',
              value: squareAreaTo,
              max: 999999
            }
          ]}
          visibility={['дом', 'участок'].includes(category)}
          suffix="сот"
          onChange={(v) => {
            setSquareAreaFrom(v[0]);
            setSquareAreaTo(v[1]);
          }}
        />
        <Select
          title="Назначение объекта"
          options={[
            {
              type: 'option',
              text: 'Автосервис',
              value: 'автосервис'
            },
            {
              type: 'option',
              text: 'Гостиница',
              value: 'гостиница'
            },
            {
              type: 'option',
              text: 'Готовый бизнес',
              value: 'готовый бизнес'
            },
            {
              type: 'option',
              text: 'Коммерческая земля',
              value: 'коммерческая земля'
            },
            {
              type: 'option',
              text: 'Общепит',
              value: 'общепит'
            },
            {
              type: 'option',
              text: 'Офисное помещение',
              value: 'офисное помещение'
            },
            {
              type: 'option',
              text: 'ПСН',
              value: 'псн'
            },
            {
              type: 'option',
              text: 'Производственное помещение',
              value: 'производственное помещение'
            },
            {
              type: 'option',
              text: 'Склад',
              value: 'склад'
            },
            {
              type: 'option',
              text: 'Торговое помещение',
              value: 'торговое помещение'
            }
          ]}
          selected={['псн']}
          visibility={['коммерция'].includes(category)}
          multiple
          onChange={(v) => setCommercialType(v)}
        />
        <div className={css.actions}>
          <Modal
            title="Предложить объект"
            visibility={isModalSuccessVisible}
            onClose={() => setIsModalSuccessVisible(false)}
          >
            <div style={{ marginBottom: 18 }}>
              Спасибо за вашу заявку. Мы скоро с Вами свяжемся!
            </div>
            <img src="/images/man-with-message.svg" alt="" style={{ margin: 32 }} />
          </Modal>
          <Modal
            title="Предложить объект"
            button="Отправить"
            action={handleFormSubmit}
            visibility={isModalSuggestVisible}
            onClose={() => setIsModalSuggestVisible(false)}
          >
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные и мы с Вами свяжемся в самые кратчайшие сроки, поможем
              с реализацией Вашего объекта!
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Иван Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) =>  setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <div style={{ margin: '18px -12px', borderBottom: '1px dashed rgba(0, 0, 0, 0.05)' }} />
            <Radio
              title="Тип сделки"
              radios={[
                { text: 'Продать', value: 'продать' },
                { text: 'Сдать', value: 'сдать', disabled: objectCategory === 'участок' }
              ]}
              selected={objectType}
              onChange={(v) => setObjectType(v)}
            />
            <Radio
              title="Категория объекта"
              radios={[
                { text: 'Квартира', value: 'квартира' },
                { text: 'Комната', value: 'комната' },
                { text: 'Дом', value: 'дом' },
                { text: 'Участок', value: 'участок', disabled: objectType === 'сдать' },
                { text: 'Коммерция', value: 'коммерция' }
              ]}
              selected={objectCategory}
              onChange={(v) => setObjectCategory(v)}
            />
            <Input
              title="Адрес объекта"
              inputs={[
                {
                  placeholder: locationState.name + ', улица Пушкина, д 123',
                  value: objectAddress,
                  type: 'text'
                }
              ]}
              onChange={(v) =>  setObjectAddress(v[0])}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/faq/policy" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Modal>
          <Button
            onClick={handleSearchClick}
            color="green"
            loading={isLoading}
            fullWidth
          >
            {foundCount > 0 ? (
              <div>
                Показать {foundCount} {declOfNum(foundCount, ['объект', 'объекта', 'объектов'])}
              </div>
            ) : (
              <div>Найти</div>
            )}
          </Button>
          {/* <Button
            onClick={() => setIsModalSuggestVisible(true)}
            fullWidth
          >
            Предложить объект
          </Button> */}
        </div>
        {/* <Button
          color="yellow"
          style={{ marginTop: 8 }}
          onClick={handleCommercialClick}
          fullWidth
        >
          Квартиры без комиссии агентства
        </Button> */}
      </div>
    </section>
  );
};

HomeWidgetMobile.defaultProps = {
  visibility: true
};

export default HomeWidgetMobile;
